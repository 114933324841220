import React from "react"
import styled from "styled-components"
import './global.css'
import ohYea from '../images/ohyea.gif'
import Particles from 'react-particles-js';

const CenteredDiv = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#dddddd',
    fontFamily: 'Open Sans',
    textAlign: 'center',
    fontSize: '2vh'
})

export default () =>
<div style={{background: '#101a22'}}>
    <div>
        <Particles style={{position: 'absolute', 'z-index': 0}} />
        <CenteredDiv style={{position: 'relative', 'z-index': 1}} >
            <div>
                <h1 style={{color: '#dddddd'}}>Heyo! I'm <span style={{color: '#008030'}}>Geoff</span>.<br />A software developer in Chicago.</h1>
            </div>
            <div>
                <img src={ohYea} alt='Oh yea!'/>
            </div>
        </CenteredDiv>
    </div>
</div>
